import { adminClient } from "../utils/axios";
import { getIfExist } from "../utils/getIfExist";
import query from "querystringify";

export const getDoctorProfilesList = queryObj => {
  return adminClient.get(`admin/doctor-profiles/${query.stringify(getIfExist(queryObj), true)}`);
};

export const addDoctorProfile = ({ doctorProfileData }) => {
  return adminClient.post("admin/doctor-profiles/", getIfExist(doctorProfileData));
};

export const getDoctorProfileDetails = ({ id }) => {
  return adminClient.get(`admin/doctor-profiles/${id}/`);
};

export const updateDoctorProfile = ({ id, doctorProfileData }) => {
  return adminClient.put(`admin/doctor-profiles/${id}/`, getIfExist(doctorProfileData));
};

export const removeDoctorProfile = id => {
  return adminClient.delete(`admin/doctor-profiles/${id}/`);
};

export const getDoctorServices = ({ id }) => {
  return adminClient.get(`admin/doctor-profiles/${id}/all-services/`);
};
