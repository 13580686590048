import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Select from "../Select/Select";
import React, { useEffect } from "react";
import { addDoctor, updateDoctor } from "../../store/actions/doctor";
import { useDispatch } from "react-redux";
import { history } from "../../utils/history";

const DoctorEditForm = ({
  doctorProfile = {},
  specializations,
  services,
  buttonText = "Submit",
}) => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    specializations: Yup.array()
      .of(Yup.number())
      .min(1, "Please select at least one specialization")
      .required("Specializations is required"),
    additional_services: Yup.array(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState, setValue, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (doctorProfile.id) {
      setValue("first_name", doctorProfile.first_name || "");
      setValue("last_name", doctorProfile.last_name || "");
      setValue("specializations", doctorProfile.specializations || []);
      setValue("additional_services", doctorProfile.additional_services || "");
    }
  }, [doctorProfile, setValue]);

  const checkIfRequired = fieldName => {
    const fieldSchema = validationSchema.describe().fields[fieldName];

    return fieldSchema.tests.some(test => test.name === "required" || test.name === "min");
  };

  const onSubmit = ({ first_name, last_name, specializations, additional_services }) => {
    if (doctorProfile.id) {
      dispatch(
        updateDoctor({
          id: doctorProfile.id,
          first_name,
          last_name,
          specializations,
          additional_services,
        }),
      );
    } else {
      dispatch(addDoctor({ first_name, last_name, specializations, additional_services }));
    }

    history.navigate("/doctors");
  };

  return (
    <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`First Name${checkIfRequired("first_name") ? " *" : ""}`}</label>
            <input
              name="first_name"
              id="first_name"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.first_name,
              })}
              placeholder="First Name"
              {...register("first_name")}
            />
            <div className="invalid-feedback">{errors.first_name?.message}</div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Last Name${checkIfRequired("last_name") ? " *" : ""}`}</label>
            <input
              name="last_name"
              id="last_name"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.last_name,
              })}
              placeholder="Last Name"
              {...register("last_name")}
            />
            <div className="invalid-feedback">{errors.last_name?.message}</div>
          </div>
        </div>

        {/*<div className="col-md-6">*/}
        {/*  <div className="mb-3">*/}
        {/*    <label className="form-label">Your Email</label>*/}
        {/*    <input*/}
        {/*      name="email"*/}
        {/*      id="email"*/}
        {/*      type="email"*/}
        {/*      className="form-control"*/}
        {/*      placeholder="Your email :"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="col-md-6">*/}
        {/*  <div className="mb-3">*/}
        {/*    <label className="form-label">Phone no.</label>*/}
        {/*    <input*/}
        {/*      name="number"*/}
        {/*      id="number"*/}
        {/*      type="text"*/}
        {/*      className="form-control"*/}
        {/*      placeholder="Phone no. :"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Specializations${checkIfRequired("specializations") ? " *" : ""}`}</label>
            <Select
              isSearchable={false}
              onChange={setValue}
              name="specializations"
              optionsData={specializations}
              valueField="id"
              labelField="name"
              placeholder="Select specialization"
              className={classNames("form-control", "p-0", {
                "is-invalid": errors.specializations,
              })}
              initialValue={watch("specializations")}
            />
            <div className="invalid-feedback">{errors.specializations?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Additional Services${checkIfRequired("additional_services") ? " *" : ""}`}</label>
            <Select
              isSearchable={false}
              onChange={setValue}
              name="additional_services"
              optionsData={services}
              valueField="id"
              labelField="name"
              placeholder="Select services"
              className={classNames("form-control", "p-0", {
                "is-invalid": errors.additional_services,
              })}
              initialValue={watch("additional_services")}
            />
            <div className="invalid-feedback">{errors.additional_services?.message}</div>
          </div>
        </div>

        {/*<div className="col-md-6">*/}
        {/*  <div className="mb-3">*/}
        {/*    <label className="form-label">Gender</label>*/}
        {/*    <select className="form-select form-control">*/}
        {/*      <option defaultValue="EY">Male</option>*/}
        {/*      <option defaultValue="GY">Female</option>*/}
        {/*    </select>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="col-md-6">*/}
        {/*  <div className="mb-3">*/}
        {/*    <label className="form-label">Instagram</label>*/}
        {/*    <div className="input-group flex-nowrap">*/}
        {/*      <span*/}
        {/*        className="input-group-text bg-light border border-end-0 text-dark"*/}
        {/*        id="insta-id"*/}
        {/*      >*/}
        {/*        <FiInstagram className="fea icon-sm" />*/}
        {/*      </span>*/}
        {/*      <input*/}
        {/*        type="text"*/}
        {/*        className="form-control"*/}
        {/*        placeholder="Username"*/}
        {/*        aria-label="Username"*/}
        {/*        aria-describedby="insta-id"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="col-md-6">*/}
        {/*  <div className="mb-3">*/}
        {/*    <label className="form-label">Facebook</label>*/}
        {/*    <div className="input-group flex-nowrap">*/}
        {/*      <span*/}
        {/*        className="input-group-text bg-light border border-end-0 text-dark"*/}
        {/*        id="fb-id"*/}
        {/*      >*/}
        {/*        <FiFacebook className="fea icon-sm" />*/}
        {/*      </span>*/}
        {/*      <input*/}
        {/*        type="text"*/}
        {/*        className="form-control"*/}
        {/*        placeholder="Username"*/}
        {/*        aria-label="Username"*/}
        {/*        aria-describedby="fb-id"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="col-md-6">*/}
        {/*  <div className="mb-3">*/}
        {/*    <label className="form-label">Linkedin</label>*/}
        {/*    <div className="input-group flex-nowrap">*/}
        {/*      <span*/}
        {/*        className="input-group-text bg-light border border-end-0 text-dark"*/}
        {/*        id="linke-pro"*/}
        {/*      >*/}
        {/*        <FiLinkedin className="fea icon-sm" />*/}
        {/*      </span>*/}
        {/*      <input*/}
        {/*        type="text"*/}
        {/*        className="form-control"*/}
        {/*        placeholder="Username"*/}
        {/*        aria-label="Username"*/}
        {/*        aria-describedby="linke-pro"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="col-md-6">*/}
        {/*  <div className="mb-3">*/}
        {/*    <label className="form-label">Twitter</label>*/}
        {/*    <div className="input-group flex-nowrap">*/}
        {/*      <span*/}
        {/*        className="input-group-text bg-light border border-end-0 text-dark"*/}
        {/*        id="twitter-id"*/}
        {/*      >*/}
        {/*        <FiTwitter className="fea icon-sm" />*/}
        {/*      </span>*/}
        {/*      <input*/}
        {/*        type="text"*/}
        {/*        className="form-control"*/}
        {/*        placeholder="Username"*/}
        {/*        aria-label="Username"*/}
        {/*        aria-describedby="twitter-id"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="col-md-12">*/}
        {/*  <div className="mb-3">*/}
        {/*    <label className="form-label">Your Bio Here</label>*/}
        {/*    <textarea*/}
        {/*      name="comments"*/}
        {/*      id="comments"*/}
        {/*      rows="3"*/}
        {/*      className="form-control"*/}
        {/*      placeholder="Bio :"*/}
        {/*    ></textarea>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <button disabled={isSubmitting} className="btn btn-primary">
        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
        {buttonText}
      </button>
    </form>
  );
};

export default DoctorEditForm;
