import { createSlice } from "@reduxjs/toolkit";
import { getSpecializations } from "../actions/specialization";

const initialState = {
  list: [],
};

export const specializationsSliceName = "specializations";

export const specializationSlice = createSlice({
  name: specializationsSliceName,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSpecializations.fulfilled, (state, action) => {
      state.list = action.payload.data;
    });
  },
});

export default specializationSlice.reducer;
