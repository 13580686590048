import Modal from "react-bootstrap/Modal";
import React from "react";
import blankImagePath from "../../assets/images/blank-image-icon.png";
import moment from "moment/moment";
import { getPatientImage } from "../../utils/mockImages";

const ShortInfoProfileModal = ({
  isShowShortProfileInfo,
  handleCloseShortProfile,
  shortInfoPatientData,
}) => {
  return (
    <>
      {shortInfoPatientData && (
        <Modal show={isShowShortProfileInfo} onHide={() => handleCloseShortProfile()} centered>
          <Modal.Header closeButton>
            <h5 className="modal-title" id="exampleModalLabel1">
              Profile
            </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center">
              <img
                src={shortInfoPatientData.img || getPatientImage(shortInfoPatientData.id)}
                className="avatar avatar-small rounded-pill"
                alt=""
              />
              <h5 className="mb-0 ms-3">{`${shortInfoPatientData?.first_name} ${shortInfoPatientData?.last_name}`}</h5>
            </div>
            <ul className="list-unstyled mb-0 d-md-flex justify-content-between mt-4">
              <li>
                <ul className="list-unstyled mb-0">
                  <li className="d-flex ms-0">
                    <h6>Age:</h6>
                    <p className="text-muted ms-2">
                      {shortInfoPatientData.birth_date
                        ? moment().diff(
                            moment(shortInfoPatientData.birth_date, "YYYY-MM-DD"),
                            "years",
                          )
                        : ""}
                    </p>
                  </li>

                  <li className="d-flex ms-0">
                    <h6>Gender:</h6>
                    <p className="text-muted ms-2">{shortInfoPatientData.gender}</p>
                  </li>

                  {/*<li className="d-flex ms-0">*/}
                  {/*  <h6 className="mb-0">Department:</h6>*/}
                  {/*  <p className="text-muted ms-2 mb-0">Cardiology</p>*/}
                  {/*</li>*/}
                </ul>
              </li>
              {/*<li>*/}
              {/*  <ul className="list-unstyled mb-0">*/}
              {/*    <li className="d-flex ms-0">*/}
              {/*      <h6>Date:</h6>*/}
              {/*      <p className="text-muted ms-2">20th Dec 2020</p>*/}
              {/*    </li>*/}

              {/*    <li className="d-flex ms-0">*/}
              {/*      <h6>Time:</h6>*/}
              {/*      <p className="text-muted ms-2">11:00 AM</p>*/}
              {/*    </li>*/}

              {/*    <li className="d-flex ms-0">*/}
              {/*      <h6 className="mb-0">Doctor:</h6>*/}
              {/*      <p className="text-muted ms-2 mb-0">Dr. Calvin Carlo</p>*/}
              {/*    </li>*/}
              {/*  </ul>*/}
              {/*</li>*/}
            </ul>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ShortInfoProfileModal;
