import { adminClient, allAuthClient, oAuthClient } from "../utils/axios";

export const authLogin = data => {
  return allAuthClient.post("auth/login", data);
};

export const authRefreshToken = formData => {
  return oAuthClient.post("token/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getUserPermissions = token => {
  return adminClient.get("permissions/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
