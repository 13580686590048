import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { getUserPermissions } from "../api/auth";

export const useTerminationToken = () => {
  const token = useSelector(s => s.auth.tokens?.access_token);
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (token && isInitialMount.current) {
      isInitialMount.current = false;

      const fetchData = async () => {
        await getUserPermissions(token);
      };

      fetchData();
    }
  }, []);
};
