import { adminClient } from "../utils/axios";
import query from "querystringify";
import { getIfExist } from "../utils/getIfExist";

export const getPatientProfilesList = queryObj => {
  return adminClient.get(`admin/patient-profiles/${query.stringify(getIfExist(queryObj), true)}`);
};

export const addPatientProfile = ({ patientProfileData }) => {
  return adminClient.post("admin/patient-profiles/", getIfExist(patientProfileData));
};

export const getPatientProfileDetails = ({ id }) => {
  return adminClient.get(`admin/patient-profiles/${id}/`);
};

export const updatePatientProfile = ({ id, patientProfileData }) => {
  return adminClient.put(`admin/patient-profiles/${id}/`, getIfExist(patientProfileData));
};

export const removePatientProfile = id => {
  return adminClient.delete(`admin/patient-profiles/${id}/`);
};
