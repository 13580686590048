import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { Controller } from "react-hook-form";

const CustomAsyncSelect = ({
  name,
  placeholder,
  control,
  rules,
  isMulti = false,
  getOptions,
  defaultOption,
  limit,
  className,
  initialOption,
}) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (initialOption && selected === null) {
      setSelected(initialOption);
    }
  }, [initialOption, selected]);

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await getOptions({ search: search, page, page_size: limit });

    return {
      options: defaultOption ? [defaultOption, ...response.options] : response.options,
      hasMore: response.hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const onChange = option => {
          setSelected(option);
          field.onChange(isMulti ? option.map(opt => opt.value) : option.value);
        };

        return (
          <div className="select">
            <AsyncPaginate
              placeholder={placeholder}
              value={selected}
              loadOptions={loadOptions}
              onChange={onChange}
              debounceTimeout={500}
              additional={{
                page: 1,
              }}
              className={className}
              styles={{
                control: styles => ({
                  ...styles,
                  border: 0,
                }),
                singleValue: styles => ({
                  ...styles,
                  display: "flex",
                  alignItems: "center",
                }),
                option: styles => ({
                  ...styles,
                  display: "flex",
                  alignItems: "center",
                }),
              }}
            />
            {error && <div className="invalid-feedback">{error.message}</div>}
          </div>
        );
      }}
    />
  );
};

export default CustomAsyncSelect;
