import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Wrapper from "../../components/wrapper";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import multiMonthPlugin from "@fullcalendar/multimonth";

import * as calendarActions from "../../store/actions/calendar";
import { deleteCalendarEvent } from "../../store/actions/calendar";
import moment from "moment";
import EventEditForm from "../../components/calendar/EventEditForm";
import {
  resetDialogEventValues,
  setDialogEventValues,
  setSchedulePeriod,
} from "../../store/reducers/calendarSlice";
import { isCheckTimeConflict } from "../../config/settings";

export default function Calendar() {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const [isShowEditForm, setIsShowEditForm] = useState(false);

  const schedule = useSelector(s => s.calendar.schedule.list);
  const businessHours = useSelector(s => s.calendar.schedule.businessHours);
  const unavailableList = useSelector(s => s.calendar.schedule.unavailableList);
  const calendarApi = calendarRef?.current?.getApi();

  useEffect(() => {
    dispatch(calendarActions.getBusinessHours());
  }, [dispatch]);

  const getEvents = () => {
    const eventsList = schedule.reduce((acc, item) => {
      if (item.entry_type === "APPOINTMENT") {
        acc.push({
          id: item.id,
          title: `Doctor: ${item.doctor}, Patient: ${item.patient.first_name} ${item.patient.last_name}`,
          start: item.start,
          end: item.end,
          extendedProps: { eventData: item },
          ...(isCheckTimeConflict && {
            constraint: "businessHours",
          }),
        });
      }

      if (item.entry_type === "CLINIC_UNAVAILABLE") {
        acc.push({
          id: `restriction-${item.id}`,
          title: `"CLINIC_UNAVAILABLE"${item.comment && `: ${item.comment}`}`,
          start: item.start,
          end: item.end,
          display: "background",
          backgroundColor: "var(--fc-non-business-color)",
          groupId: item.entry_type,
        });
      }

      if (item.entry_type === "DOCTOR_UNAVAILABLE") {
        acc.push({
          id: `restriction-${item.id}`,
          title: `Doctor: ${item.doctor} ${item.comment || "DOCTOR_UNAVAILABLE"}`,
          start: item.start,
          end: item.end,
          display: "background",
          backgroundColor: "var(--fc-non-business-color)",
          groupId: `doctor-${item.doctor}`,
        });
      }

      return acc;
    }, []);

    const unavailableDaysEvents = unavailableList.reduce((acc, item) => {
      if (!item.doctor) {
        acc.push({
          id: `restriction-${item.date}`,
          title: item.comment || "Clinic Unavailable",
          start: item.date,
          end: moment(item.date).add(1, "days").format("YYYY-MM-DD"),
          ...(isCheckTimeConflict && {
            overlap: "false",
          }),
          display: "background",
          backgroundColor: "#ff9f89",
        });
      } else {
        acc.push({
          id: `restriction-${item.doctor}`,
          title: `Doctor: ${item.doctor} ${item.comment || "DOCTOR_UNAVAILABLE"}`,
          start: item.date,
          end: moment(item.date).add(1, "days").format("YYYY-MM-DD"),
          display: "background",
          backgroundColor: "var(--fc-non-business-color)",
          groupId: `doctor-${item.doctor}`,
        });
      }

      return acc;
    }, []);

    return [...eventsList, ...unavailableDaysEvents];
  };

  const handleClose = () => {
    calendarApi.unselect();
    dispatch(resetDialogEventValues());
    setIsShowEditForm(false);
  };

  const handleDelete = id => {
    dispatch(deleteCalendarEvent({ id }));
    dispatch(resetDialogEventValues());
    setIsShowEditForm(false);
  };

  const handleShow = () => setIsShowEditForm(true);

  function handleDateSelect(info) {
    if (isCheckTimeConflict) {
      const selectedDate = moment(info.start).format("YYYY-MM-DD");
      const calendarView = info.view.type;

      const isDayUnavailable = unavailableList.some(
        unavailable => !unavailable.doctor && unavailable.date === selectedDate,
      );

      if (isDayUnavailable) {
        alert("Cannot create events on this day - Clinic unavailable");
        calendarApi.unselect();

        return;
      }

      if (calendarView === "dayGridMonth") {
        const eventDayOfWeek = moment(info.start).isoWeekday();

        const isWithinBusinessDay = businessHours.some(hours => {
          return hours.daysOfWeek.includes(eventDayOfWeek);
        });

        if (!isWithinBusinessDay) {
          alert("Cannot create events on this day - Clinic closed on this day");
          calendarApi.unselect();

          return;
        }

        dispatch(
          setDialogEventValues({
            start: moment(info.start).format("YYYY-MM-DDTHH:mm:ssZ"),
            end: moment(info.end).format("YYYY-MM-DDTHH:mm:ssZ"),
          }),
        );
        handleShow();

        return;
      }

      const isWithinHours = isWithinBusinessHours(info.start, info.end);

      if (!isWithinHours) {
        alert("Cannot create events on this day - Outside of Business Hours");
        calendarApi.unselect();

        return;
      }

      const isRestrictedTime = schedule.some(event => {
        const isInRestrictedPeriod =
          event.entry_type === "CLINIC_UNAVAILABLE" &&
          moment(info.start).isBetween(event.start, event.end, null, "[]");

        const isStartingAtEndOfRestrictedPeriod =
          event.entry_type === "CLINIC_UNAVAILABLE" && moment(info.start).isSame(event.end);

        return isInRestrictedPeriod && !isStartingAtEndOfRestrictedPeriod;
      });

      if (isRestrictedTime) {
        alert("Cannot create events on this day - Clinic unavailable");
        calendarApi.unselect();

        return;
      }
    }

    dispatch(
      setDialogEventValues({
        start: moment(info.start).format("YYYY-MM-DDTHH:mm:ssZ"),
        end: moment(info.end).format("YYYY-MM-DDTHH:mm:ssZ"),
      }),
    );
    handleShow();
  }

  const handleEventClick = info => {
    if (info.event.groupId) {
      calendarApi.unselect();

      return;
    }

    calendarApi.unselect();
    dispatch(calendarActions.getEventDetails({ id: info.event.id }));
    handleShow();
  };

  function handleEvents(events) {}

  const handleDatesSet = info => {
    dispatch(
      setSchedulePeriod({
        start: moment(info.start).format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(info.end).format("YYYY-MM-DDTHH:mm:ss"),
      }),
    );
    dispatch(calendarActions.getCalendarList());
    dispatch(calendarActions.getUnavailableList());
  };

  const handleEventChange = info => {
    dispatch(
      calendarActions.updateCalendarEvent({
        id: info.event.id,
        eventData: {
          start: moment(info.event.start).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          end: moment(info.event.end).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        },
      }),
    );
  };

  const miniCalendarHandleDateClick = info => {
    calendarApi.changeView("timeGridDay");
    calendarApi.gotoDate(info.date);
  };

  const isWithinBusinessHours = (eventStart, eventEnd) => {
    const eventDayOfWeek = eventStart.getUTCDay();
    const eventStartTime = moment(eventStart).format("HH:mm");
    const eventEndTime = moment(eventEnd).format("HH:mm");

    for (const hours of businessHours) {
      if (hours.daysOfWeek.includes(eventDayOfWeek)) {
        const startTime = moment(hours.startTime, "HH:mm").format("HH:mm");
        const endTime = moment(hours.endTime, "HH:mm").format("HH:mm");

        if (eventStartTime >= startTime && eventStartTime < endTime && eventEndTime <= endTime) {
          return true;
        }
      }
    }

    return false;
  };

  const getEventsByGroupId = groupId => {
    const calendarApi = calendarRef.current.getApi();

    return calendarApi.getEvents().filter(event => event.groupId === groupId);
  };

  const getEventsByDoctorId = doctorId => {
    const calendarApi = calendarRef.current.getApi();

    return calendarApi.getEvents().filter(event => {
      return event.extendedProps?.eventData?.doctor === doctorId;
    });
  };

  const checkHasConflict = (events, eventStart, eventEnd, eventId) => {
    return events.some(existingEvent => {
      if (eventId && existingEvent.id === eventId) {
        return false;
      }

      const groupStart = moment(existingEvent.start);
      const groupEnd = moment(existingEvent.end);

      const isStartSameAsGroupEnd = eventStart.isSame(groupEnd);
      const isEndSameAsGroupStart = eventEnd.isSame(groupStart);

      if (isStartSameAsGroupEnd || isEndSameAsGroupStart) {
        return false;
      }

      const isStartWithinRange = eventStart.isBetween(groupStart, groupEnd, null, "[]");
      const isEndWithinRange = eventEnd.isBetween(groupStart, groupEnd, null, "[]");

      return isStartWithinRange || isEndWithinRange;
    });
  };

  const eventAllow = (dropInfo, draggedEvent) => {
    if (isCheckTimeConflict) {
      const eventStart = moment(dropInfo.start);
      const eventEnd = moment(dropInfo.end);
      const eventId = draggedEvent.id;
      const doctorId = draggedEvent.extendedProps.eventData.doctor;

      const doctorEventsUnavailable = getEventsByGroupId(`doctor-${doctorId}`);
      const clinicEventsUnavailable = getEventsByGroupId("CLINIC_UNAVAILABLE");
      const doctorScheduleEvents = getEventsByDoctorId(doctorId);

      const isDoctorUnavailableConflict = checkHasConflict(
        doctorEventsUnavailable,
        eventStart,
        eventEnd,
      );
      const isClinicUnavailableConflict = checkHasConflict(
        clinicEventsUnavailable,
        eventStart,
        eventEnd,
      );
      const isDoctorScheduleConflict = checkHasConflict(
        doctorScheduleEvents,
        eventStart,
        eventEnd,
        eventId,
      );

      return (
        !isDoctorUnavailableConflict && !isClinicUnavailableConflict && !isDoctorScheduleConflict
      );
    }

    return true;
  };

  return (
    <Wrapper>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="d-md-flex justify-content-between">
            {/*    <h5 className="mb-0">Calendar</h5>*/}

            {/*    <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">*/}
            {/*      <ul className="breadcrumb bg-transparent rounded mb-0 p-0">*/}
            {/*        <li className="breadcrumb-item">*/}
            {/*          <Link to="/">Doctris</Link>*/}
            {/*        </li>*/}
            {/*        <li className="breadcrumb-item active" aria-current="page">*/}
            {/*          Calendar*/}
            {/*        </li>*/}
            {/*      </ul>*/}
            {/*    </nav>*/}
            {/*  </div>*/}

            {/*<div className="row">*/}
            {/*  <div className="col-xl-3 col-lg-4 col-12 mt-4">*/}
            {/*    <div id="external-events">*/}
            {/*      <div className="card border-0 p-4 shadow rounded">*/}
            {/*        <FullCalendar*/}
            {/*          plugins={[multiMonthPlugin, interactionPlugin]}*/}
            {/*          initialView="multiMonthOneMonth"*/}
            {/*          views={{*/}
            {/*            multiMonthOneMonth: {*/}
            {/*              type: "multiMonth",*/}
            {/*              duration: { months: 1 },*/}
            {/*            },*/}
            {/*          }}*/}
            {/*          editable={true}*/}
            {/*          selectable={true}*/}
            {/*          dateClick={miniCalendarHandleDateClick}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            <div className="col-xl-12col-lg-12 col-12 mt-4">
              <div id="calendar-container" className="card rounded border-0 shadow p-4">
                <div id="calendar"></div>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listDay",
                  }}
                  ref={calendarRef}
                  initialView="timeGridWeek"
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  allDaySlot={false}
                  unselectAuto={false}
                  nowIndicator={true}
                  events={getEvents()}
                  businessHours={businessHours}
                  datesSet={handleDatesSet}
                  select={handleDateSelect}
                  // eventContent={renderEventContent}
                  eventClick={handleEventClick}
                  eventsSet={handleEvents}
                  eventChange={handleEventChange}
                  eventAllow={eventAllow}
                  // dayCellClassNames={arg => {
                  //   const selectedDate = moment(arg.date).format("YYYY-MM-DD");
                  //
                  //   // Проверяем, есть ли день в unavailableList
                  //   const isDayUnavailable = unavailableList.some(
                  //     unavailable => !unavailable.doctor && unavailable.date === selectedDate,
                  //   );
                  //
                  //   // Если день недоступен, добавляем класс unavailable-day
                  //   if (isDayUnavailable) {
                  //     return ["unavailable-day"];
                  //   }
                  //
                  //   return []; // Если день доступен, не добавляем никаких классов
                  // }}
                />
              </div>
            </div>
            <EventEditForm
              isShowEditForm={isShowEditForm}
              handleClose={handleClose}
              handleDelete={handleDelete}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b style={{ color: "red" }}>{eventInfo.timeText}</b>
      <i style={{ marginLeft: "5px" }}>{eventInfo.event.title}</i>
    </>
  );
}
