import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./reducers/authSlice";
import mainReducer from "./reducers/mainSlice";
import calendarReducer from "./reducers/calendarSlice";
import doctorReducer from "./reducers/doctorSlice";
import patientReducer from "./reducers/patientSlice";
import submitsSlice from "./reducers/submitsSlice";
import specializationSlice from "./reducers/specializationSlice";
import servicesSlice from "./reducers/servicesSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  main: mainReducer,
  calendar: calendarReducer,
  doctors: doctorReducer,
  patients: patientReducer,
  submits: submitsSlice,
  specializations: specializationSlice,
  services: servicesSlice,
});

export default rootReducer;
