import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addPatientProfile,
  getPatientProfileDetails,
  getPatientProfilesList,
  removePatientProfile,
  updatePatientProfile,
} from "../../api/patient";
import { patientsSliceName } from "../reducers/patientSlice";
import { getScheduleList } from "../../api/calendar";

export const getPatientsList = createAsyncThunk(
  `${patientsSliceName}/list`,
  async (queryObj, thunkAPI) => {
    try {
      const { page, page_size } = thunkAPI.getState().patients;
      const query = {
        page,
        page_size,
        ...(queryObj && { ...queryObj }),
      };
      const response = await getPatientProfilesList(query);

      return {
        data: response.data,
        page: +query.page,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getPatientProfile = createAsyncThunk(
  `${patientsSliceName}/getProfile`,
  async ({ id }, thunkAPI) => {
    try {
      const response = await getPatientProfileDetails({
        id,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const addPatient = createAsyncThunk(
  `${patientsSliceName}/add`,
  async (patientData, thunkAPI) => {
    try {
      const response = await addPatientProfile({
        patientProfileData: patientData,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updatePatient = createAsyncThunk(
  `${patientsSliceName}/update`,
  async (patientData, thunkAPI) => {
    try {
      const response = await updatePatientProfile({
        id: patientData.id,
        patientProfileData: patientData,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const removePatient = createAsyncThunk(
  `${patientsSliceName}/deleteProfile`,
  async (id, thunkAPI) => {
    try {
      const response = await removePatientProfile(id);

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getPatientAppointments = createAsyncThunk(
  `${patientsSliceName}/getAppointments`,
  async (patient, thunkAPI) => {
    try {
      const response = await getScheduleList({
        patient,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
