import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "../../utils/history";

const PrivateRoute = () => {
  const auth = useSelector(s => s.auth);

  if (!auth.isLoggedIn) return <Navigate to="/login" state={{ from: history.location }} />;

  return <Outlet />;
};

export default PrivateRoute;
