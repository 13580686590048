import { adminClient } from "../utils/axios";
import query from "querystringify";
import { getIfExist } from "../utils/getIfExist";

export const getScheduleList = ({ start, end, doctor_id, patient }) => {
  return adminClient.get(
    `admin/schedule-entries/${query.stringify(getIfExist({ start, end, doctor_id, patient }), true)}`,
  );
};

export const addScheduleEvent = ({ eventData }) => {
  return adminClient.post("admin/schedule-entries/", getIfExist(eventData));
};

export const getScheduleEventDetails = ({ id }) => {
  return adminClient.get(`admin/schedule-entries/${id}/`);
};

export const updateScheduleEvent = ({ id, eventData }) => {
  return adminClient.put(`admin/schedule-entries/${id}/`, getIfExist(eventData));
};

export const removeScheduleEvent = id => {
  return adminClient.delete(`admin/schedule-entries/${id}/`);
};

export const getClinicWorkingHours = () => {
  return adminClient.get(`common/calendar/business_hours/`);
};
