import { createSlice } from "@reduxjs/toolkit";
import { login } from "../actions/auth";
import { history } from "../../utils/history";

const initialState = {
  isLoggedIn: false,
  error: null,
  tokens: null,
  user: null,
  permissions: [],
};

export const authSliceName = "auth";

export const authSlice = createSlice({
  name: authSliceName,
  initialState,
  reducers: {
    logout: () => {
      const { from } = history.location.state || { from: { pathname: "/login" } };
      history.navigate(from);

      return {
        ...initialState,
      };
    },
    changeToken: (state, action) => {
      state.tokens.access_token = action.payload.access_token;
      state.tokens.refresh_token = action.payload.refresh_token;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const { data, meta, permissions } = action.payload;
        state.isLoggedIn = true;
        state.error = null;
        state.user = data.user;
        state.tokens = meta;
        state.permissions = permissions;
        const { from } = history.location.state || { from: { pathname: "/" } };
        history.navigate(from);
      })
      .addCase(login.rejected, (state, action) => {
        return {
          ...initialState,
          error: action.payload.message,
        };
      });
  },
});

export const { logout, changeToken } = authSlice.actions;

export default authSlice.reducer;
