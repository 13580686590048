import { createSlice } from "@reduxjs/toolkit";
import { getServices } from "../actions/services";

const initialState = {
  list: [],
};

export const servicesSliceName = "services";

export const servicesSlice = createSlice({
  name: servicesSliceName,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.list = action.payload.data;
    });
  },
});

export default servicesSlice.reducer;
