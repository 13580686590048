import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addScheduleEvent,
  getClinicWorkingHours,
  getScheduleEventDetails,
  getScheduleList,
  removeScheduleEvent,
  updateScheduleEvent,
} from "../../api/calendar";
import { getUnavailableDates } from "../../api/unavailableDates";
import { calendarSliceName } from "../reducers/calendarSlice";

export const getCalendarList = createAsyncThunk(
  `${calendarSliceName}/list`,
  async (_, thunkAPI) => {
    try {
      const { period, doctorId } = thunkAPI.getState().calendar.schedule;
      const response = await getScheduleList({
        start: period.start,
        end: period.end,
        doctor_id: doctorId,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getUnavailableList = createAsyncThunk(
  `${calendarSliceName}/unavailableList`,
  async (_, thunkAPI) => {
    try {
      const { period, doctorId } = thunkAPI.getState().calendar.schedule;
      const response = await getUnavailableDates({
        start: period.start,
        end: period.end,
        doctor_id: doctorId,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getEventDetails = createAsyncThunk(
  `${calendarSliceName}/itemDetails`,
  async ({ id }, thunkAPI) => {
    try {
      const response = await getScheduleEventDetails({ id });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateCalendarEvent = createAsyncThunk(
  `${calendarSliceName}/eventUpdate`,
  async ({ id, eventData }, thunkAPI) => {
    try {
      const event = thunkAPI.getState().calendar.schedule.list.find(item => item.id === +id);
      const response = await updateScheduleEvent({
        id,
        eventData: {
          ...event,
          ...eventData,
          patient_id: eventData?.patient_id || event.patient.id,
        },
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const submitEventForm = createAsyncThunk(
  `${calendarSliceName}/eventSubmitForm`,
  async ({ id, eventData }, thunkAPI) => {
    try {
      let response;

      if (id) {
        const event = thunkAPI.getState().calendar.schedule.list.find(item => item.id === +id);
        response = await updateScheduleEvent({
          id,
          eventData: {
            ...event,
            ...eventData,
            patient_id: eventData?.patient_id || event.patient.id,
          },
        });
      } else {
        response = await addScheduleEvent({
          eventData,
        });
      }

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const deleteCalendarEvent = createAsyncThunk(
  `${calendarSliceName}/eventDelete`,
  async ({ id }, thunkAPI) => {
    try {
      await removeScheduleEvent(id);

      return {
        data: id,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getBusinessHours = createAsyncThunk(
  `${calendarSliceName}/getBusinessHours`,
  async (_, thunkAPI) => {
    try {
      const response = await getClinicWorkingHours();

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
