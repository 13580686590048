import axios from "axios";
import { ADMIN_API_URL, ALLAUTH_API_URL, O_AUTH_API_URL } from "../config/api";

const defaultHeaders = {
  "Content-Type": "application/json",
};

const allAuthClient = axios.create({
  baseURL: ALLAUTH_API_URL,
});

const oAuthClient = axios.create({
  baseURL: O_AUTH_API_URL,
});

const adminClient = axios.create({
  baseURL: ADMIN_API_URL,
});

allAuthClient.defaults.headers = {
  ...defaultHeaders,
};

oAuthClient.defaults.headers = {
  ...defaultHeaders,
};

adminClient.defaults.headers = {
  ...defaultHeaders,
};

export { allAuthClient, adminClient, oAuthClient };
