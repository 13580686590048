import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCalendarEvent,
  getBusinessHours,
  getCalendarList,
  getEventDetails,
  getUnavailableList,
  submitEventForm,
  updateCalendarEvent,
} from "../actions/calendar";

const initialState = {
  schedule: {
    period: {
      start: null,
      end: null,
    },
    doctorId: null,
    list: [],
    businessHours: [],
    unavailableList: [],
  },
  dialog: {
    selected: null,
  },
  loader: {
    isLoading: false,
    loadingText: "",
  },
};

export const calendarSliceName = "calendar";

export const calendarSlice = createSlice({
  name: calendarSliceName,
  initialState,
  reducers: {
    setSchedulePeriod: (state, action) => {
      state.schedule.period.start = action.payload.start;
      state.schedule.period.end = action.payload.end;
    },
    setDialogEventValues: (state, action) => {
      state.dialog.selected = action.payload;
    },
    resetDialogEventValues: state => {
      state.dialog.selected = initialState.dialog.selected;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCalendarList.fulfilled, (state, action) => {
        state.schedule.list = action.payload.data.results;
      })
      .addCase(getUnavailableList.fulfilled, (state, action) => {
        state.schedule.unavailableList = action.payload.data;
      })
      .addCase(updateCalendarEvent.fulfilled, (state, action) => {
        const modifiedEvent = action.payload.data;
        state.schedule.list = state.schedule.list.map(item =>
          +item.id === modifiedEvent.id ? modifiedEvent : item,
        );
      })
      .addCase(getEventDetails.fulfilled, (state, action) => {
        state.dialog.selected = action.payload.data;
      })
      .addCase(submitEventForm.fulfilled, (state, action) => {
        const event = action.payload.data;
        const currentEvent = state.schedule.list.find(item => +item.id === event.id);

        if (currentEvent) {
          state.schedule.list = state.schedule.list.map(item =>
            +item.id === event.id ? event : item,
          );
        } else state.schedule.list.push(event);
      })
      .addCase(deleteCalendarEvent.fulfilled, (state, action) => {
        state.schedule.list = state.schedule.list.filter(item => +item.id !== action.payload.data);
      })
      .addCase(getBusinessHours.fulfilled, (state, action) => {
        state.schedule.businessHours = action.payload.data;
      });
  },
});

export const { setSchedulePeriod, setDialogEventValues, resetDialogEventValues } =
  calendarSlice.actions;

export default calendarSlice.reducer;
