import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { history } from "../../utils/history";
import { FaRegCalendarAlt } from "react-icons/fa";
import moment from "moment";
import Select from "../Select/Select";
import { addPatient, updatePatient } from "../../store/actions/patient";

const PatientEditForm = ({ patientProfile = {}, buttonText = "Submit" }) => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    middle_name: Yup.string(),
    birth_date: Yup.date().max(new Date(), "Birthday cannot be in the future"),
    gender: Yup.string(),
    primary_phone_number: Yup.string()
      .transform(value => (value ? value.replace(/[^0-9]/g, "") : ""))
      .nullable()
      .test("phone-length", "Phone number is not valid", value => {
        return !value || (value.length >= 10 && value.length <= 15);
      })
      .optional(),
    secondary_phone_number: Yup.string()
      .transform(value => (value ? value.replace(/[^0-9]/g, "") : ""))
      .nullable()
      .test("phone-length", "Phone number is not valid", value => {
        return !value || (value.length >= 10 && value.length <= 15);
      })
      .optional(),
    passport_number: Yup.string(),
    medicate_number: Yup.string(),
    address: Yup.string(),
    religion: Yup.string(),
    nationality: Yup.string(),
    is_atsis: Yup.boolean(),
    is_war_veteran: Yup.boolean(),
    is_pensioner: Yup.boolean(),
    gp: Yup.string(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState, setValue, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (patientProfile.id) {
      setValue("first_name", patientProfile.first_name || "");
      setValue("last_name", patientProfile.last_name || "");
      setValue("middle_name", patientProfile.middle_name || "");
      setValue("birth_date", patientProfile.birth_date || "");
      setValue("gender", patientProfile.gender || "");
      setValue("primary_phone_number", patientProfile.primary_phone_number || "");
      setValue("secondary_phone_number", patientProfile.secondary_phone_number || "");
      setValue("passport_number", patientProfile.passport_number || "");
      setValue("medicate_number", patientProfile.medicate_number || "");
      setValue("address", patientProfile.address || "");
      setValue("religion", patientProfile.religion || "");
      setValue("nationality", patientProfile.nationality || "");
      setValue("is_atsis", patientProfile.is_atsis || false);
      setValue("is_war_veteran", patientProfile.is_war_veteran || false);
      setValue("is_pensioner", patientProfile.is_pensioner || false);
      setValue("gp", patientProfile.gp || "");
    }
  }, [patientProfile, setValue]);

  const checkIfRequired = fieldName => {
    const fieldSchema = validationSchema.describe().fields[fieldName];

    return fieldSchema.tests.some(test => test.name === "required" || test.name === "min");
  };

  const onSubmit = ({
    first_name,
    last_name,
    middle_name,
    birth_date,
    gender,
    primary_phone_number,
    secondary_phone_number,
    passport_number,
    medicate_number,
    address,
    religion,
    nationality,
    is_atsis,
    is_war_veteran,
    is_pensioner,
    gp,
  }) => {
    if (patientProfile.id) {
      dispatch(
        updatePatient({
          id: patientProfile.id,
          first_name,
          last_name,
          middle_name,
          birth_date: birth_date ? moment(birth_date).format("YYYY-MM-DD") : null,
          gender,
          primary_phone_number,
          secondary_phone_number,
          passport_number,
          medicate_number,
          address,
          religion,
          nationality,
          is_atsis,
          is_war_veteran,
          is_pensioner,
          gp,
        }),
      );
    } else {
      dispatch(
        addPatient({
          first_name,
          last_name,
          middle_name,
          birth_date: birth_date ? moment(birth_date).format("YYYY-MM-DD") : null,
          gender,
          primary_phone_number,
          secondary_phone_number,
          passport_number,
          medicate_number,
          address,
          religion,
          nationality,
          is_atsis,
          is_war_veteran,
          is_pensioner,
          gp,
        }),
      );
      history.navigate("/patients");
    }
  };

  return (
    <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`First Name${checkIfRequired("first_name") ? " *" : ""}`}</label>
            <input
              name="first_name"
              id="first_name"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.first_name,
              })}
              placeholder="First Name"
              {...register("first_name")}
            />
            <div className="invalid-feedback">{errors.first_name?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Last Name${checkIfRequired("last_name") ? " *" : ""}`}</label>
            <input
              name="last_name"
              id="last_name"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.last_name,
              })}
              placeholder="Last Name"
              {...register("last_name")}
            />
            <div className="invalid-feedback">{errors.last_name?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Middle Name${checkIfRequired("middle_name") ? " *" : ""}`}</label>
            <input
              name="middle_name"
              id="middle_name"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.middle_name,
              })}
              placeholder="Middle Name"
              {...register("middle_name")}
            />
            <div className="invalid-feedback">{errors.middle_name?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3 d-flex flex-column">
            <label className="form-label">{`Birthday${checkIfRequired("birth_date") ? " *" : ""}`}</label>
            <DatePicker
              showIcon
              closeOnScroll={true}
              showYearDropdown
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              maxDate={moment().toDate()}
              minDate={moment().subtract(100, "years").toDate()}
              icon={<FaRegCalendarAlt />}
              selected={
                watch("birth_date") ? moment(watch("birth_date"), "YYYY-MM-DD").toDate() : null
              }
              onChange={date => {
                setValue("birth_date", date ? moment(date).format("YYYY-MM-DD") : "");
              }}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select birth date"
              className={classNames("form-control", {
                "is-invalid": errors.birth_date,
              })}
            />
            {errors.birth_date && (
              <div className="invalid-feedback d-block">{errors.birth_date.message}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Gender${checkIfRequired("gender") ? " *" : ""}`}</label>
            <Select
              isSearchable={false}
              isMulti={false}
              onChange={setValue}
              name="gender"
              optionsData={[
                { value: "M", name: "Male" },
                { value: "F", name: "Female" },
                { value: "O", name: "Other" },
              ]}
              valueField="value"
              labelField="name"
              placeholder="Select gender"
              className={classNames("form-control", "p-0", {
                "is-invalid": errors.gender,
              })}
              initialValue={watch("gender")}
            />
            <div className="invalid-feedback">{errors.gender?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Primary Phone Number${checkIfRequired("primary_phone_number") ? " *" : ""}`}</label>
            <InputMask
              mask="+9 (999) 999-9999"
              name="primary_phone_number"
              id="primary_phone_number"
              type="tel"
              className={classNames("form-control", {
                "is-invalid": errors.primary_phone_number,
              })}
              placeholder="Primary Phone Number"
              {...register("primary_phone_number")}
            />
            <div className="invalid-feedback">{errors.primary_phone_number?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Secondary Phone Number${checkIfRequired("secondary_phone_number") ? " *" : ""}`}</label>
            <InputMask
              mask="+9 (999) 999-9999"
              name="secondary_phone_number"
              id="secondary_phone_number"
              type="tel"
              className={classNames("form-control", {
                "is-invalid": errors.secondary_phone_number,
              })}
              placeholder="Secondary Phone Number"
              {...register("secondary_phone_number")}
            />
            <div className="invalid-feedback">{errors.secondary_phone_number?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Passport Number${checkIfRequired("passport_number") ? " *" : ""}`}</label>
            <input
              name="passport_number"
              id="passport_number"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.passport_number,
              })}
              placeholder="Passport Number"
              {...register("passport_number")}
            />
            <div className="invalid-feedback">{errors.passport_number?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Medicate Number${checkIfRequired("medicate_number") ? " *" : ""}`}</label>
            <input
              name="medicate_number"
              id="medicate_number"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.medicate_number,
              })}
              placeholder="Medicate Number"
              {...register("medicate_number")}
            />
            <div className="invalid-feedback">{errors.medicate_number?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Address${checkIfRequired("address") ? " *" : ""}`}</label>
            <input
              name="address"
              id="address"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.address,
              })}
              placeholder="Address"
              {...register("address")}
            />
            <div className="invalid-feedback">{errors.address?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Religion${checkIfRequired("religion") ? " *" : ""}`}</label>
            <input
              name="religion"
              id="religion"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.religion,
              })}
              placeholder="Religion"
              {...register("religion")}
            />
            <div className="invalid-feedback">{errors.religion?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Nationality${checkIfRequired("nationality") ? " *" : ""}`}</label>
            <input
              name="nationality"
              id="nationality"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.nationality,
              })}
              placeholder="Nationality"
              {...register("nationality")}
            />
            <div className="invalid-feedback">{errors.nationality?.message}</div>
          </div>
        </div>
        <div className="form-check">
          <div className="px-3">
            <input
              className={classNames("form-check-input", {
                "is-invalid": errors.is_atsis,
              })}
              type="checkbox"
              value=""
              id="is_atsis"
              {...register("is_atsis")}
            />
            <label className="form-check-label" htmlFor="is_atsis">
              {`Is atsis${checkIfRequired("is_atsis") ? " *" : ""}`}
            </label>
          </div>
        </div>
        <div className="form-check">
          <div className="px-3">
            <input
              className={classNames("form-check-input", {
                "is-invalid": errors.is_war_veteran,
              })}
              type="checkbox"
              value=""
              id="is_war_veteran"
              {...register("is_war_veteran")}
            />
            <label className="form-check-label" htmlFor="is_war_veteran">
              {`Is war veteran${checkIfRequired("is_war_veteran") ? " *" : ""}`}
            </label>
          </div>
        </div>
        <div className="form-check">
          <div className="px-3">
            <input
              className={classNames("form-check-input", {
                "is-invalid": errors.is_pensioner,
              })}
              type="checkbox"
              value=""
              id="is_pensioner"
              {...register("is_pensioner")}
            />
            <label className="form-check-label" htmlFor="is_pensioner">
              {`Is pensioner${checkIfRequired("is_pensioner") ? " *" : ""}`}
            </label>
          </div>
        </div>
        <div className="col-md-12">
          <div className="mb-3">
            <label className="form-label">{`GP${checkIfRequired("nationality") ? " *" : ""}`}</label>
            <textarea
              name="gp"
              id="gp"
              rows="3"
              className={classNames("form-control", {
                "is-invalid": errors.gp,
              })}
              placeholder="GP"
              {...register("gp")}
            />
            <div className="invalid-feedback">{errors.gp?.message}</div>
          </div>
        </div>
      </div>
      <button disabled={isSubmitting} className="btn btn-primary">
        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
        {buttonText}
      </button>
    </form>
  );
};

export default PatientEditForm;
