import doctor1 from "../assets/images/doctors/01.jpg";
import doctor2 from "../assets/images/doctors/02.jpg";
import doctor3 from "../assets/images/doctors/03.jpg";
import doctor4 from "../assets/images/doctors/04.jpg";
import doctor5 from "../assets/images/doctors/05.jpg";
import doctor6 from "../assets/images/doctors/06.jpg";
import doctor7 from "../assets/images/doctors/07.jpg";
import doctor8 from "../assets/images/doctors/08.jpg";
import doctor9 from "../assets/images/doctors/09.jpg";
import doctor10 from "../assets/images/doctors/10.jpg";

import client1 from "../assets/images/client/01.jpg";
import client2 from "../assets/images/client/02.jpg";
import client3 from "../assets/images/client/03.jpg";
import client4 from "../assets/images/client/04.jpg";
import client5 from "../assets/images/client/05.jpg";
import client6 from "../assets/images/client/06.jpg";
import client7 from "../assets/images/client/07.jpg";
import client8 from "../assets/images/client/08.jpg";
import client9 from "../assets/images/client/09.jpg";
import client10 from "../assets/images/client/10.jpg";

const doctorImages = {
  0: doctor10,
  1: doctor1,
  2: doctor2,
  3: doctor3,
  4: doctor4,
  5: doctor5,
  6: doctor6,
  7: doctor7,
  8: doctor8,
  9: doctor9,
};

const patientImages = {
  0: client10,
  1: client1,
  2: client2,
  3: client3,
  4: client4,
  5: client5,
  6: client6,
  7: client7,
  8: client8,
  9: client9,
};

function getLastDigit(number) {
  return Math.abs(number) % 10;
}

export const getDoctorImage = id => {
  const lastDigit = getLastDigit(+id);

  return doctorImages[lastDigit];
};

export const getPatientImage = id => {
  const lastDigit = getLastDigit(+id);

  return patientImages[lastDigit];
};
