import { history } from "../../utils/history";
import { useEffect, useState } from "react";
import { pagesWithSearch } from "../../config/settings";
import { DebounceInput } from "react-debounce-input";

const Search = () => {
  const [isShowSearch, setIsShowSearch] = useState(false);
  const location = history.location;
  const [searchValue, setSearchValue] = useState(
    Object.fromEntries(new URLSearchParams(location.search))?.search || "",
  );

  useEffect(() => {
    const showSearch = pagesWithSearch.some(path => {
      const regex = new RegExp(`^${path}$`);

      return regex.test(location.pathname);
    });

    setIsShowSearch(showSearch);
  }, [location.pathname]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentParams = Object.fromEntries(params.entries());

    if (
      currentParams.page &&
      searchValue &&
      currentParams.page !== "1" &&
      searchValue !== currentParams.search
    ) {
      params.set("page", "1");
    }

    if (searchValue) {
      params.set("search", searchValue);
    } else {
      params.delete("search");
    }

    history.navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  }, [searchValue, location.pathname, location.search]);

  const handleInputChange = event => {
    setSearchValue(event.target.value);
  };

  return (
    <>
      {isShowSearch && (
        <div className="search-bar p-0 ms-2">
          <div id="search" className="menu-search mb-0">
            <form role="search" id="searchform" className="searchform">
              <div>
                <DebounceInput
                  type="text"
                  debounceTimeout={700}
                  className="form-control border rounded-pill"
                  placeholder="Search Keywords..."
                  value={searchValue}
                  onChange={handleInputChange}
                />
                <input type="submit" id="searchsubmit" defaultValue="Search" disabled />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Search;
