import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSpecializationsList } from "../../api/specialization";
import { specializationsSliceName } from "../reducers/specializationSlice";

export const getSpecializations = createAsyncThunk(
  `${specializationsSliceName}/list`,
  async (_, thunkAPI) => {
    try {
      const response = await getSpecializationsList();

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
