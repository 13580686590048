import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as doctorActions from "../../store/actions/doctor";
import { submitEventForm } from "../../store/actions/calendar";
import moment from "moment/moment";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import CustomAsyncSelect from "../Select/CustomAsyncSelect";
import { getDoctorProfilesList } from "../../api/doctor";
import { getPatientProfilesList } from "../../api/patient";
import { getServices } from "../../store/actions/services";
import PopoutSelect from "../Select/PopoutSelect";
import DatePicker from "react-datepicker";
import { FaRegCalendarAlt } from "react-icons/fa";

const EventEditForm = ({ isShowEditForm, handleClose, handleDelete }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const doctors = useSelector(s => s.doctors.list);
  const services = useSelector(s => s.services.list);
  const eventItem = useSelector(s => s.calendar.dialog.selected);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    doctor: Yup.string().required("Doctor is required"),
    patient_id: Yup.string().required("Patient is required"),
    entry_type: Yup.string(),
    entry_services: Yup.array(),
    start: Yup.string().required("Start is required"),
    end: Yup.string().required("End is required"),
    comment: Yup.string(),
  });
  const defaultValues = {
    doctor: "",
    patient_id: "",
    entry_type: "APPOINTMENT",
    start: "",
    end: "",
    comment: "",
    entry_services: [],
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  };
  const methods = useForm(formOptions);
  const { register, handleSubmit, formState, setValue, reset, control, watch } = methods;
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (isShowEditForm) {
      dispatch(doctorActions.getDoctorsList({ isWithQueries: false }));
      dispatch(getServices());
    }
  }, [dispatch, isShowEditForm]);

  useEffect(() => {
    if (eventItem?.id) {
      reset({
        doctor: eventItem.doctor || "",
        patient_id: eventItem.patient.id || "",
        entry_type: eventItem.entry_type || "APPOINTMENT",
        start: eventItem.start || "",
        end: eventItem.end || "",
        comment: eventItem.comment || "",
        entry_services: eventItem.entry_services || [],
      });

      setIsEditMode(true);
    } else {
      setValue("entry_type", "APPOINTMENT", {
        shouldDirty: false,
      });
      setValue("start", eventItem?.start, {
        shouldDirty: true,
      });
      setValue("end", eventItem?.end, {
        shouldDirty: true,
      });

      setIsEditMode(false);
    }
  }, [eventItem, reset, setValue]);

  const onSubmit = ({ doctor, patient_id, entry_type, start, end, comment, entry_services }) => {
    return dispatch(
      submitEventForm({
        id: eventItem?.id || null,
        eventData: {
          doctor,
          patient_id,
          entry_type,
          start: moment(start).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          end: moment(end).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          comment,
          entry_services,
        },
      }),
    ).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        reset(defaultValues);
        handleClose();
      } else {
        console.error("Submission failed:", response.payload);
      }
    });
  };

  const handleFormClose = () => {
    if (formState.isDirty) {
      dispatch(
        showSubmitConfirmation({
          message: "Are you sure you want to exit? All unsaved data will be lost.",
          onConfirm: () => {
            reset(defaultValues);
            handleClose();
          },
        }),
      );
    }

    if (!formState.isDirty) {
      reset(defaultValues);
      handleClose();
    }
  };

  const handleFormDelete = () => {
    dispatch(
      showSubmitConfirmation({
        message: "Are you sure you want to delete event?",
        onConfirm: () => {
          reset(defaultValues);
          handleDelete(eventItem.id);
        },
      }),
    );
  };

  const getPatientsData = async requestObj => {
    return getPatientProfilesList(requestObj).then(response => {
      return {
        options: response.data.results.map(option => {
          return {
            value: option.id,
            label: `${option.first_name} ${option.last_name}`,
          };
        }),
        hasMore: !!response.data.next,
      };
    });
  };

  const getDoctorsData = async requestObj => {
    return getDoctorProfilesList(requestObj).then(response => {
      return {
        options: response.data.results.map(option => {
          return {
            value: option.id,
            label: `${option.first_name} ${option.last_name}`,
          };
        }),
        hasMore: !!response.data.next,
      };
    });
  };

  return (
    <div>
      <Offcanvas
        show={isShowEditForm}
        onHide={() => {
          if (isEditMode) {
            handleFormClose();
          }
        }}
        backdrop={isEditMode}
        keyboard={false}
        placement="end"
        className="offcanvasEventEditForm"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>{`${isEditMode ? "Edit" : "Create"} Form`}</Offcanvas.Title>
          <div className="d-flex gap-1">
            {isEditMode && (
              <Button variant="danger" onClick={handleFormDelete}>
                Delete
              </Button>
            )}
            <Button variant="secondary" onClick={handleFormClose}>
              Close
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FormProvider {...methods}>
            <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Doctor</label>
                    <CustomAsyncSelect
                      name="doctor"
                      placeholder="Select doctor"
                      control={control}
                      getOptions={getDoctorsData}
                      {...(isEditMode && {
                        initialOption: {
                          value: eventItem?.doctor,
                          label: `${doctors.reduce((acc, doctor) => (doctor.id === eventItem?.doctor ? `${doctor.first_name} ${doctor.last_name}` : acc), "")}`,
                        },
                      })}
                      limit={10}
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.doctor,
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Patient</label>
                    <CustomAsyncSelect
                      name="patient_id"
                      placeholder="Select patient"
                      control={control}
                      getOptions={getPatientsData}
                      {...(isEditMode && {
                        initialOption: {
                          value: eventItem?.patient?.id,
                          label: `${eventItem?.patient?.first_name} ${eventItem?.patient?.last_name}`,
                        },
                      })}
                      limit={10}
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.patient_id,
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Services</label>
                    <PopoutSelect
                      name="entry_services"
                      placeholder="Select services"
                      control={control}
                      optionsData={services}
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.entry_services,
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Date & Time</label>
                    <div className="d-flex gap-2">
                      <DatePicker
                        showIcon
                        closeOnScroll={true}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown // maxDate={moment().toDate()}
                        minDate={moment().subtract(100, "years").toDate()}
                        icon={<FaRegCalendarAlt />}
                        selected={watch("start") ? moment.parseZone(watch("start")).toDate() : null}
                        onChange={date => {
                          setValue(
                            "start",
                            moment(watch("start"), moment.ISO_8601)
                              .set({
                                year: moment(date).year(),
                                month: moment(date).month(),
                                date: moment(date).date(),
                              })
                              .format("YYYY-MM-DDTHH:mm:ssZ"),
                            {
                              shouldDirty: true,
                            },
                          );
                        }}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select date"
                        className={classNames("form-control", {
                          "is-invalid": errors.start,
                        })}
                      />
                      <div className="d-flex align-items-center">
                        <span className="text-muted">{"from: "}</span>
                        <DatePicker
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          selected={
                            watch("start") ? moment.parseZone(watch("start")).toDate() : null
                          }
                          onChange={time => {
                            setValue(
                              "start",
                              moment(watch("start"), moment.ISO_8601)
                                .set({
                                  hour: moment(time).hour(),
                                  minute: moment(time).minute(),
                                })
                                .format("YYYY-MM-DDTHH:mm:ssZ"),
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                          placeholderText="Select start time"
                          className={classNames("form-control", "d-inline-block", {
                            "is-invalid": errors.start,
                          })}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="text-muted">{"to: "}</span>
                        <DatePicker
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          selected={watch("end") ? moment.parseZone(watch("end")).toDate() : null}
                          onChange={time => {
                            setValue(
                              "end",
                              moment(watch("end"), moment.ISO_8601)
                                .set({
                                  hour: moment(time).hour(),
                                  minute: moment(time).minute(),
                                })
                                .format("YYYY-MM-DDTHH:mm:ssZ"),
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                          placeholderText="Select end time"
                          className={classNames("form-control", "d-inline-block", {
                            "is-invalid": errors.end,
                          })}
                        />
                      </div>
                    </div>
                    {errors.start && (
                      <div className="invalid-feedback d-block">{errors.start.message}</div>
                    )}
                    {errors.end && (
                      <div className="invalid-feedback d-block">{errors.end.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Comment</label>
                    <textarea
                      name="comment"
                      rows="3"
                      className={classNames("form-control", {
                        "is-invalid": errors.comment,
                      })}
                      placeholder="Comment"
                      {...register("comment")}
                    />
                    <div className="invalid-feedback">{errors.comment?.message}</div>
                  </div>
                </div>
                <div className="col-lg-12 mb-0">
                  <div className="d-grid">
                    <button
                      disabled={isSubmitting || !formState.isDirty}
                      className="btn btn-primary"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default EventEditForm;
