import { Link } from "react-router-dom";
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from "../../assets/icons/vander";
import React from "react";
import blankImagePath from "../../assets/images/blank-image-icon.png";
import { useSelector } from "react-redux";
import { getDoctorImage } from "../../utils/mockImages";

const DoctorItem = ({ item }) => {
  const specializations = useSelector(s => s.specializations.list);

  const getSpecializationsDoctorData = list => {
    return list.reduce((acc, specializationId, index) => {
      const specialization = specializations.find(item => item.id === specializationId)?.name;

      if (specialization) {
        acc += specialization + (index < list.length - 1 ? ", " : "");
      }

      return acc;
    }, "");
  };

  return (
    <div className="col mt-4">
      <div className="card team border-0 rounded shadow overflow-hidden">
        <div className="team-img position-relative">
          <img src={item?.image || getDoctorImage(item.id)} className="img-fluid" alt="" />
          {/*<ul className="list-unstyled team-social mb-0">*/}
          {/*  <li>*/}
          {/*    <Link to="#" className="btn btn-icon btn-pills btn-soft-primary">*/}
          {/*      <FiFacebook className="icons" />*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*  <li className="mt-2">*/}
          {/*    <Link to="#" className="btn btn-icon btn-pills btn-soft-primary">*/}
          {/*      <FiLinkedin className="icons" />*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*  <li className="mt-2">*/}
          {/*    <Link to="#" className="btn btn-icon btn-pills btn-soft-primary">*/}
          {/*      <FiInstagram className="icons" />*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*  <li className="mt-2">*/}
          {/*    <Link to="#" className="btn btn-icon btn-pills btn-soft-primary">*/}
          {/*      <FiTwitter className="icons" />*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </div>
        <div className="card-body content text-center">
          <Link to={`/doctors/${item.id}`} className="title text-dark h5 d-block mb-0">
            {`${item.first_name} ${item.last_name}`}
          </Link>
          <small className="text-muted speciality">
            {getSpecializationsDoctorData(item.specializations)}
          </small>
          {/*{item.additional_services.map((service, index) => {*/}
          {/*  return (*/}
          {/*    <small key={service} className="text-muted speciality">*/}
          {/*      {`${service}${index !== item.additional_services.length - 1 && ", "}`}*/}
          {/*    </small>*/}
          {/*  );*/}
          {/*})}*/}
        </div>
      </div>
    </div>
  );
};

export default DoctorItem;
