import Modal from "react-bootstrap/Modal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSubmitConfirmation } from "../../store/reducers/submitsSlice";

const ConfirmationModal = () => {
  const { isShow, message, onConfirm } = useSelector(s => s.submits);
  const dispatch = useDispatch();

  const handleConfirmClick = () => {
    onConfirm();
    dispatch(closeSubmitConfirmation());
  };

  const handleCancelClick = () => {
    dispatch(closeSubmitConfirmation());
  };

  return (
    <Modal show={isShow} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton={false}>
        <h5 className="modal-title">{message}</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-end">
          <button className="btn btn-secondary me-2" onClick={handleCancelClick}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleConfirmClick}>
            Ok
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
