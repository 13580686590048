import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServicesList } from "../../api/services";
import { servicesSliceName } from "../reducers/servicesSlice";

export const getServices = createAsyncThunk(`${servicesSliceName}/list`, async (_, thunkAPI) => {
  try {
    const response = await getServicesList();

    return {
      data: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
});
