import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addDoctorProfile,
  getDoctorProfileDetails,
  getDoctorProfilesList,
  getDoctorServices,
  removeDoctorProfile,
  updateDoctorProfile,
} from "../../api/doctor";
import { doctorSliceName } from "../reducers/doctorSlice";

export const getDoctorsList = createAsyncThunk(
  `${doctorSliceName}/list`,
  async ({ queryObj, isWithQueries = true }, thunkAPI) => {
    try {
      const { page, page_size } = thunkAPI.getState().doctors;
      const query = {
        page,
        page_size,
        ...(queryObj && { ...queryObj }),
      };
      const response = await getDoctorProfilesList(isWithQueries && query);

      return {
        data: response.data,
        page: +query.page,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getDoctorProfile = createAsyncThunk(
  `${doctorSliceName}/getProfile`,
  async ({ id }, thunkAPI) => {
    try {
      const response = await getDoctorProfileDetails({
        id,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const addDoctor = createAsyncThunk(
  `${doctorSliceName}/add`,
  async ({ first_name, last_name, specializations, additional_services }, thunkAPI) => {
    try {
      const response = await addDoctorProfile({
        doctorProfileData: { first_name, last_name, specializations, additional_services },
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateDoctor = createAsyncThunk(
  `${doctorSliceName}/update`,
  async ({ id, first_name, last_name, specializations, additional_services }, thunkAPI) => {
    try {
      const response = await updateDoctorProfile({
        id,
        doctorProfileData: { id, first_name, last_name, specializations, additional_services },
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const removeDoctor = createAsyncThunk(
  `${doctorSliceName}/deleteProfile`,
  async (id, thunkAPI) => {
    try {
      const response = await removeDoctorProfile(id);

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getDoctorAllServices = createAsyncThunk(
  `${doctorSliceName}/getAllServices`,
  async ({ id }, thunkAPI) => {
    try {
      const response = await getDoctorServices({
        id,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
